* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

::selection {
  background: #000;
  color: #fff;
}
::-moz-selection {
  background: #000;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root > div {
  height: auto !important;
}

.toasterContainer .innerToaster {
  border: none;
  border-radius: 4px;
  line-height: 20px;
  color: #666666;
  padding: 0 16px;
}

.toasterContainer .innerToaster .toasterButton {
  border: 0px solid #777777;
  width: 36px;
  height: 36px;
  align-self: center;
  border-radius: 50px;
  background: #eee;
  outline: none;
  cursor: pointer;
  color: #aaa;
  font-size: 1rem;
  /* transition: '.3s ease'; */
}

.toasterContainer .innerToaster .tosterProgress {
  background: #777777;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
