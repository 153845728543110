.panel-container {
  text-align: center;
  overflow: hidden;
  margin: 30px 16px 60px 16px;
  padding: 30px 16px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16);
}

.panel-container .entities-nav .nav-btn {
  width: 160px;
  height: 34px;
}

.panel-container .panel-tag {
  font-size: 1rem;
  font-weight: 500;
}

.panel-container .panel-header {
  margin-top: 8px;
  font-size: 2.25rem;
  font-weight: 500;
}

.panel-container .nodeholder-id {
  margin-top: 8px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
}

/*** Middle Section, Panel Details ***/

.panel-container .panel-details {
  margin-top: 60px;
}

.panel-container .panel-details .detail-section {
  margin-bottom: 30px;
}

.panel-container .panel-details .detail-header {
  font-size: 1rem;
  font-weight: 500;
}

.panel-container .panel-details .nodeholder-name {
  margin-top: 16px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
}

.panel-container .panel-details .detail-handler {
  width: 100px;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.54);
  background-color: #f2f2f2;
}

.panel-container .panel-details .detail-handler.edit {
  padding: 2px 35px;
  border-radius: 4px;
}

.panel-container .panel-details .detail-handler.verify {
  padding: 6.5px 14px;
  border-radius: 4px;
}

/*** Navigation and Controls ***/

.panel-container .sign-out {
  font-size: 16px;
  font-weight: 400;
  color: #a1a1a1;
  text-transform: capitalize;
}

.panel-container .entities-btn {
  float: left;
  margin-top: 40px;
  max-width: 160px;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 400;
  flex-grow: 1;
}

.panel-container .reserve-btn {
  float: right;
  margin-left: 12px;
  margin-top: 40px;
  max-width: 160px;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 400;
  flex-grow: 1;
}

.panel-container .header {
  font-size: 24px;
  font-weight: 500;
  margin: 60px 0;
}

.panel-container .bottom-link {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

/*** Reserved Entities ***/

.reserved-container {
  padding: 0 20px;
}

.reserved-container .nh-icon {
  background: url('../../assets/icons/nodeholder/no-fill.svg') no-repeat center
    center;
  background-size: contain;
  height: 35px;
  width: 35px;
  margin: 30px auto 16px auto;
}

.reserved-container .header {
  margin: 0 0 8px 0;
  font-size: 1.875rem;
}

.reserved-container .entity-row {
  margin-bottom: 60px;
}

.reserved-container .entityHeader {
  text-align: initial;
  font-size: 16px;
  font-weight: 500;
  color: #aaa;
}

.reserved-container .entityContent {
  margin-top: 12px;
  text-align: initial;
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
  word-break: break-word;
}

.reserved-container {
  min-height: 460px;
  position: relative;
}

.panel-container .entity-item-container-name {
  max-width: 70%;
}

.panel-container .entity-item-container {
  max-width: 30%;
}

.reserved-container .pageination-container {
}

.reserved-container .entity-counter {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 60px;
}

@media screen and (min-width: 600px) {
  .panel-container {
    margin: 60px auto;
    padding: 30px 60px 80px 60px;
    max-width: 600px;
    border: 1px solid #dadce0;
    border-radius: 8px;
  }
}
