.header-container {
  text-align: center;
  color: #333;
}

.header-container .dch-icon {
  margin-top: 100px;
}

.header-container .header {
  margin-top: 12px;
  font-size: 2.375rem;
  font-weight: 500;
}

.header-container .sub-header {
  margin-top: 10px;
  font-size: 20px;
  letter-spacing: 0.15;
}
