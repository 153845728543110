/* Entity Formation - Landing Page */

.landing-page-container .landing-page-card {
  max-width: 327px;
  background-color: #fff;
  box-shadow: 0px 0px 8px #5353531a;
  border-radius: 4px;
  margin: 60px auto;
  padding: 60px 24px 120px;
}

@media screen and (min-width: 600px) {
  .landing-page-container .landing-page-card {
    max-width: 400px;
  }
}

body.entity-formation-bg {
  background: #f8f8f8;
}

.entity-formation-container {
  margin: 0 auto;
  text-align: center;
}

.carousel-container .carousel-content {
  /* background: #fcfcfc; */
  margin: 24px 10px 20px;
  padding: 40px 27px;
  /* box-shadow: 0px 0px 8px #5353531a; */
  border-radius: 2px;
}

.create-entity-form {
  text-align: center;
  margin: 0 auto;
}

.entity-name-bg {
  box-shadow: 0px 0px 8px #5353531a;
  background: #fcfcfc;
  padding: 40px 24px;
}

.create-entity-form .form-header {
  width: 100%;
  margin: 60px 0px 0px 0px;
  padding: 0px;
  font-size: 1.125rem;
  text-align: center;
  color: #7a7a7a;
  letter-spacing: 0;
}

.create-entity-form .header {
  margin-top: 16px;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 42px;
  color: #111111;
  font-weight: 500;
  text-align: center;
}
/* .create-entity-form svg {
  height: 100px;
  width: 100px;
} */
.entity-name-header {
  margin: 16px auto 0;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: #707070;
}

.create-entity-form .header .sub-header {
  margin: 8px auto 0 auto;
  font-size: 1rem;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #333333;
  font-weight: 500;
  text-align: center;
}

.create-entity-form .header .center-text {
  font-weight: 600;
  font-size: 14px;
}

.create-entity-form .text-field-top {
  margin: 0 auto;
  margin-top: 75px;
  width: 274px;
}

.create-entity-form .step {
  background-color: rgb(228, 228, 228);
  border-radius: 0.8em;
  font-size: 16px;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin: 20px 0 40px 0;
  margin-right: 8px;
  text-align: center;
  width: 1.6em;
}

.create-entity-form .entity-class {
  padding: 20px;
}

.create-entity-form .bottom-helper-text {
  margin: 12px 0;
  font-weight: 600;
  font-size: 12px;
}

.create-entity-form .entity-class span {
  font-size: 14px;
}

.create-entity-form .entity-class .button {
  margin-top: 30px;
}

.create-entity-form .more-info-container {
  margin: 0 20px 80px 20px;
  padding: 5px;
  font-size: 12px;
  background-color: rgb(228, 228, 228);
}

.create-entity-form .more-info-container .act-link {
  font-weight: 500;
  color: #3f51b5;
}

.create-entity-form .bottom-helper-text {
  margin-bottom: 40px;
}

/*** Reserve Spot ***/

.create-entity-form .register-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: solid 1px gray;
}

.create-entity-form .register-container .header {
  font-size: 36px;
  letter-spacing: 1px;
  line-height: 42px;
  color: #111111;
  font-weight: 500;
  text-align: center;
}

.create-entity-form .register-container .sub-header {
  font-size: 12px;
  margin-bottom: 60px;
}

.create-entity-form .register-container .button {
  margin-bottom: 20px;
}

.entity-class-sub {
  display: block;
  font-size: 1.25rem;
  font-weight: 500;
  color: #707070;
  margin: 10px 0 12px 0;
}

.mt-30 {
  margin-top: 30px;
}

.entity-class-desc {
  margin: 0 0 40px;
  padding: 0;
  /* min-height: 60px; */
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #9e9e9e;
  line-height: 25px !important;
}

.entity-class-btn {
  margin: 0 auto;
  cursor: pointer;
  background: #535353;
  padding: 12px 25px;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.42px;
  border: 0;
  border-radius: 4px;
  text-transform: capitalize !important;
}

.entity-class-disable {
  background-color: #d8d8d8;
  border-radius: 4px;
  letter-spacing: 0.42px;
  color: #999999;
  margin: 0 auto;
  cursor: pointer;
  padding: 12px 25px;
  font-size: 14px;
  text-transform: capitalize !important;

  border: 0;
}

.entity-name-btn {
  margin: 0 auto;
  cursor: pointer;
  background-color: #535353 !important;
  padding: 9px 25px !important;
  line-height: 1;
  font-size: 14px;
  color: #fff !important;
  letter-spacing: 0.42px;
  border: 0;
  border-radius: 4px;
  text-transform: capitalize !important;
  box-shadow: none !important;
}
.entity-name-d-btn {
  padding: 9px 25px !important;
}

.entity-class-btn :disabled {
  cursor: not-allowed;
}
.entity-class-name {
  margin: 0 auto 40px;
  font-weight: 500;
}

.alternatively {
  font-size: 1rem;
  letter-spacing: 0.24px;
  color: #9e9e9e;
  margin-bottom: 16px;
}

.entity-class-name .entity-type-fName {
  font-size: 1.625rem;
  font-weight: 500;
  color: #9e9e9e;
  display: inline-block;
  margin-top: 0;
}

.entity-class-name .entity-type-fName2 {
  color: #646464;
  margin-left: 5px;
  margin-top: 0;
}

.entity-class-name span {
  margin-top: 16px;
  font-size: 24px;
  font-weight: 500;
  color: #111111;
}

.eclass-carousel,
.eclass-carousel .entity-class-name {
  margin: 0 auto 10px;
  font-size: 26px;
  color: #646464;
}

.eclass-carousel svg {
  height: 120px;
  width: 120px;
}
.eclass-type-carousel svg {
  height: 100px;
  width: 100px;
}

.eclass-carousel .entity-icon {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.alice-carousel .alice-carousel__dots {
  display: none;
}

.entity-icon-container {
  margin-top: 30px;
}

/* .entity-icon-container svg {
  width: 96px;
  height: 120px;
} */

.border-input .MuiOutlinedInput-notchedOutline {
  border: 1px solid #dae1e7 !important;
  border-radius: 4px;
}
.border-input .MuiInputLabel-outlined {
  transform: translate(14px, 18px) scale(1);
  letter-spacing: 0.24px;
  color: #9e9e9e;
}
.border-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #dae1e7;
}
.border-input .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
  border: 1px solid #dae1e7;
}

.border-input .MuiOutlinedInput-input {
  padding: 16.5px 14px;
}

.entity-name-container .form-header {
  width: 100%;
  margin: 60px 0px 16px 0px;
  font-size: 1.125rem;
  text-align: center;
  color: #7a7a7a;
}
.entity-name-container .header {
  font-size: 1.625rem;
  font-weight: 500;
  color: #646464;
  margin-bottom: 10px;
  letter-spacing: 0;
}

.sle-svg svg {
  height: 140px !important;
  width: 140px !important;
}

/*** Responsive Media Queries ***/

@media screen and (max-width: 600px) {
  .entity-name-container {
    max-width: 327px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 600px) {
  .entity-formation-container {
    max-width: 700px;
    margin: 0 auto;
  }
  .entity-name-container {
    margin: 0 auto;
    max-width: 400px;
  }
  .page-desc {
    padding: 0px 130px;
  }
}

@media screen and (min-width: 991px) {
  .entity-formation-container {
    min-height: 700px;
    margin: 30px auto 0;
    padding: 0;
  }
  .entity-name-container {
    margin: 30px auto 0;
    max-width: 450px !important;
  }
  .entity-name-bg {
    padding: 40px 60px 90px;
  }
  .create-entity-form .form-header {
    margin: 30px 0px 0px 0px;
  }
}

@media screen and (max-width: 767px) {
  .alice-carousel .alice-carousel__dots {
    margin: 0px 0 40px;
    display: block;
  }

  .alice-carousel .alice-carousel__dots .alice-carousel__dots-item {
    width: 12px;
    height: 12px;
    border: 2px solid #707070;
    background: #ffffff;
  }

  .alice-carousel .alice-carousel__dots .alice-carousel__dots-item.__active {
    background: #707070;
  }

  .carousel-container .carousel-content {
    padding: 40px 24px 80px;
    margin: 0;
  }

  .alice-carousel__stage-item {
    transform: scale(0.9);
    transition: all 0.5s;
  }

  .alice-carousel__stage-item.__active {
    transform: scale(1);
  }
}

@media screen and (max-width: 320px) {
  .carousel-container .carousel-content {
    padding: 20px;
    margin: 10px;
  }

  .eclass-carousel,
  .eclass-carousel .entity-class-name {
    margin: 0 auto 20px;
  }

  .entity-class-sub {
    margin: 20px 0 15px 0;
  }
}
