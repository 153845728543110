.disabled-btn {
  color: rgba(0, 0, 0, 0.26);
  padding: 12px 30px;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: inherit;
  border-radius: 4px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  background-color: #00000029;
}

.coming-soon-container {
  margin: 60px 24px 0 24px;
  padding: 36px 24px;
  max-width: 400px;
  background-color: #fff;
  box-shadow: 0px 0px 8px #5353531a;
  border-radius: 2px;
}

.coming-soon-container h2 {
  margin-bottom: 12px;
  font-size: 26px;
  color: #646464;
}

.coming-soon-container p {
  margin-bottom: 40px;
  line-height: 25px;
  color: #9e9e9e;
}

.landing-page-container {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  align-items: center;
}

.landing-page-container .landing-page-content {
  background: #fcfcfc;
  max-width: 400px;
  margin: 60px 24px 0 24px;
  padding-bottom: 200px;
  box-shadow: 0px 0px 8px #5353531a;
  border-radius: 2px;
}

.landing-page-container .landing-form-btn {
  margin: 40px auto 0px auto;
  cursor: pointer;
  background: #535353;
  padding: 17px 25px;
  line-height: 1;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.42px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
}

.landing-page-container .page-desc {
  margin: 16px auto 0px auto;
  padding: 0 24px;
  font-size: 0.875rem;
  color: #9e9e9e;
  text-align: center;
}

@media screen and (min-width: 600px) {
  .landing-page-container .landing-page-content {
    margin: 100px auto;
  }
}

.master-form-container {
  margin-top: 16px;
  text-align: center;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.carousel-container {
  position: relative;
}
.carousel-container .carousel-content {
  margin-top: 35px;
}

.carousel-container .carousel-content .entity-names {
  font-size: 12px;
  font-weight: 600;
}
.carousel-container .prev,
.carousel-container .next {
  border: none;
  outline: none;
  background: transparent;
  position: absolute;
  top: 40%;
  transform: translateY(-22%);
  cursor: pointer;
  z-index: 99;
}

.carousel-container .prev {
  left: 0;
}
.carousel-container .next {
  right: 0;
}
.selected {
  color: #3f51b5;
}

.back-btn {
  color: #555555;
  margin-top: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.icon-class {
  background-size: contain !important;
  display: inline-block;
  height: 32px;
  width: 32px;
  padding: 15px 20px;
}
.lpage-container {
  position: relative;
}
.ipage-icon {
  /* background: url('../../assets/icons/Group 419.svg') no-repeat center center; */
  background-size: contain !important;
  display: inline-block;
  height: 200px;
  width: 200px;
  transition-property: all;
  transition-duration: 1s;
  margin: 90px auto 0 auto !important;
}
.ipage-text {
  position: relative;
  margin-top: 16px;
  font-size: 34px;
  letter-spacing: 0.25px;
  color: #333333;
  font-weight: 500;
  text-align: center;
  transition-property: all;
  transition-duration: 1s;
}
.start-fade {
  padding: 15px 20px;
  opacity: 0;
}
.text-fade {
  opacity: 0;
}
/* .formicon {
  background: url('../../assets/icons/formIcon.svg') no-repeat center center;
} */
.newDecentalized {
  background: url("../../assets/icons/de.svg") no-repeat center center;
}
.smart_legal_entity {
  background: url("../../assets/icons/entity-class/sle.svg") no-repeat center
    center;
}
.next_gen_entity {
  background: url("../../assets/icons/entity-class/nge.svg") no-repeat center
    center;
}
.decentralized_company {
  background: url("../../assets/icons/entity-type/dlc.svg") no-repeat center
    center;
}
.decentralized_partnership {
  background: url("../../assets/icons/entity-type/dlp.svg") no-repeat center
    center;
}
.next_gen_project {
  background: url("../../assets/icons/entity-type/ngp.svg") no-repeat center
    center;
}
.next_gen_fund {
  background: url("../../assets/icons/entity-type/ngf.svg") no-repeat center
    center;
}
.entity-class-btn :disabled {
  cursor: not-allowed;
}
.small-class-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
}
.eSclass {
  width: 15%;
  padding: 4px 0px;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid transparent;
  cursor: pointer;
}
.eSActive {
  border: 2px solid #4e4e4e;
  border-radius: 4px;
}
.verify-text-container {
  margin-top: 20px;
}
.availability-container {
  font-size: 1rem;
  letter-spacing: 0.15px;
  font-weight: 500;
  text-align: center;
}
.availability-container .available {
  color: #0fe45d;
  font-size: 20px;
  letter-spacing: 0;
}
.availability-container .not-available {
  color: #f81313;
  font-size: 20px;
  letter-spacing: 0;
}
.reserve-btn-container {
  margin-top: 35px;
}
.verify-btn {
  padding: 13px 0 !important;
  font-size: 14px !important;
  outline: none;
  background-color: #535353 !important;
  border-radius: 4px !important;
  cursor: pointer;
  color: #ffffff !important;
  width: 100%;
  letter-spacing: 0.42px;
}
.verify-btn-d {
  padding: 13px 0 !important;
  width: 100%;
}
.verify-text {
  margin: 16px 0 80px;
  padding: 0;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #9e9e9e;
  line-height: 25px !important;
  font-weight: 400;
}
.verified-action-container {
  margin-top: 60px;
}
.verified-action-container .verify-text {
  /* width: 90% !important; */
}
.reserve-btn-container a {
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #777777;
  text-align: center;
}
.alternate {
  margin-top: 50px;
}
.alternate a {
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
  color: #9e9e9e;
  letter-spacing: 0.24px;
}

#loader {
  position: absolute;
  left: 50%;
  top: 40%;
  z-index: 1;
  margin: 0 -60px 0 -60px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text-field-top.eqt_field {
  display: flex;
  align-items: center;
}

.eqt_btn {
  padding: 0 0 0 10px;
  font-weight: 500;
}
.border-input_text .MuiOutlinedInput-input {
  padding: 16.5px 6px !important;
  text-align: center;
}
.MuiDialog-paper .MuiDialogContent-root {
  padding: 8px 17px;
}
