.act-container .topbar-container {
  position: fixed;
  background-color: #fff;
  box-shadow: 0px 2px 6px #0000001a;
  z-index: 99;
}

.act-container .section-container {
  padding: 90px 18px;
}

.act-container .section-container:first-child {
  padding: 60px 18px 90px 18px;
}

.act-container .section-container .header {
  margin-bottom: 24px;
  font-size: 1.125rem;
  font-weight: 500;
  color: #121212;
}

.act-container .section-container .sub-header {
  margin-bottom: 16px;
  color: #353535;
  font-size: 0.875rem;
  font-weight: 500;
}

.act-container .section-container p {
  margin-bottom: 24px;
  font-size: 0.875rem;
  line-height: 25px;
  color: #666;
}

.act-container .section-container p:last-child {
  margin: 0;
}

.act-container .link-container {
  text-align: right;
  padding-right: 16px;
  font-size: 0.875rem;
  color: #121212;
}

.act-container .link-container .doc-links {
  text-decoration: none;
  color: #121212;
}

/* ** Decentralized Companies Act **

/* Anchor Points Menu */

.sidebar {
  padding: 8px;
  width: 280px;
  float: right;
  position: fixed;
  top: 12px;
  bottom: 0;
  line-height: 1.5;
  margin-top: 40px;
  box-shadow: inset -1px 0 0 0 #e3e8ee;
  right: 0;
  background-color: #fff;
  border-left: 1px solid #e3e8ee;
}

.sidebar a,
.AccountSwitcher a {
  text-decoration: none;
}

.sidebar a {
  display: block;
  font-size: 14px;
  padding: 4px 16px;
}
.sidebar .section-subheader a {
  color: #777777;
  font-weight: 500;
  padding-left: 26px;
}

.sidebar .section-header a {
  color: #535353;
  font-weight: 500;
}

.sidebar .scrollspy {
  padding-bottom: 60px;
  padding-top: 10px;
  position: absolute;
  overflow-y: auto;
  top: 0;
  height: 100%;
  bottom: 0;
}
.sidebar .scrollspy::-webkit-scrollbar {
  padding: 2px;
  width: 12px;
  height: 12px;
  background: transparent;
}
.sidebar .scrollspy:hover::-webkit-scrollbar-thumb {
  background: #c1c9d2;
  border-radius: 12px;
  border: 2px solid transparent;
  background-clip: content-box;
}
.sidebar .scrollspy::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Actived Anchor Point */

.isCurrent {
  background-color: #f7f7f7;
  border-radius: 4px;
}

.isCurrent a {
  color: #111111 !important;
}

/* Anchor Points Container */

.main-container {
  display: flex;
}
.activeLink {
  color: red;
}
.block {
  height: 400px;
}

.act-container option {
  color: #333;
  margin-bottom: 20px;
}
.act-container .mobileNav-select {
  color: hsla(0, 0%, 100%, 0);
  z-index: 100;
  font-size: 13px;
  font-weight: 500;
  max-width: 100%;
  background: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 12px 16px;
  position: absolute;
  text-transform: none;
}
.act-container .options {
  color: #333;
}
.act-container .mobileNav-label {
  padding: 12px 16px;
  color: #697386;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  left: 16px;
  display: flex;
  align-items: center;
  z-index: 99;
}
.act-container .mobileNav-select:focus {
  outline: none;
}
.act-container .mobileNav {
  display: flex;
  align-items: center;
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: 50vw;
  position: relative;
  padding: 26px;
}

@media (max-width: 1030px) {
  .act-container {
    width: 100%;
  }

  .sidebar {
    display: none;
  }
}

a,
a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}
.doc-links {
  color: #556cd6;
  color: #333;
  font-weight: 500;
  font-size: 14px;
}
.signIn-container {
  width: 279px;
  float: right;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #e3e8ee;
  background-color: white;
  padding: 12px 28px;
  right: 0;
}

.mt12 {
  margin-top: 12px;
}

.mt16 {
  margin-top: 16px;
}

.ml16 {
  margin-left: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb36 {
  margin-bottom: 36px;
}

.mb48 {
  margin-bottom: 48px;
}

@media only screen and (min-width: 768px) {
  .act-container .section-container {
    padding: 90px 64px;
  }
  
  .act-container .section-container:first-child {
    padding: 60px 64px 90px;
  }
}

@media only screen and (min-width: 1024px) {
  .act-container .section-container {
    padding: 90px 30% 90px 96px;
  }
  
  .act-container .section-container:first-child {
    padding: 60px 30% 90px 96px;
  }
}

@media only screen and (min-width: 1400px) {
  .act-container .section-container {
    padding: 90px 30% 90px 256px;
  }
  
  .act-container .section-container:first-child {
    padding: 60px 30% 90px 256px;
  }
}