.dropdown-header {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  padding-left: 16px;
}

.dropdown-menu-link {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  padding-left: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.drawer-header .header-text {
  padding: 22px 0;
  padding-left: 16px;
  font-size: 21px;
}

.MuiList-root .MuiList-padding {
  padding: 0;
}
